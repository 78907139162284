import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p>
      I am experienced in full-stack web developement, software project/product management, and analytics. My background is in Computer Science & Economics, at @UofT.
    </p> 
    <p>
      For web applications I prefer to use Elixir & the Phoenix Framework. 
    </p>
    <p>
      I am also experienced in both client-side and server-side JavaScript, React, and Ruby on Rails.
    </p>

    <p>
      Currently I spend my time between Toronto ON, Taiwan, and Houston TX. Please feel free to contact me at &lt;my-first-name&gt; @ gmail &lt;dot&gt; com.
    </p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      {/*<Image /> */}
    </div>
    <Link to="/my-work/">My Work</Link> <br />
  </Layout>
)

export default IndexPage
